import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { ViewsService } from '../../views.service';
import { ViewComponentConfigureComponent } from '../configure/view-component-configure.component';
import { DatePipe } from '@angular/common';
import { MonacoEditorLoaderService, MonacoStandaloneCodeEditor } from '@materia-ui/ngx-monaco-editor';
import { TrackingComponent } from 'src/app/tracking/tracking.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { OrganizationsService } from 'src/app/organizations/organizations.service';
import { TemplatesTypesService } from 'src/app/configuration/templates/templates-types.service';
import { LoginService } from 'src/app/login/login.service';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { CustomizerService } from '../../customizer.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ComponentService } from '../../component.service';
import { OnChanges } from '@angular/core';
import { TemplatesService } from '../../../../configuration/templates/templates.service';
import { Router } from '@angular/router';
import { OrganizationsContactsDetailComponent } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsDetailGeneralComponent } from 'src/app/organizations/organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { MailMarketingNotificationToStatsComponent } from 'src/app/mail-marketing/mail-marketing-notification-to-stats/mail-marketing-notification-to-stats.component';
import { DebugService } from 'src/app/core/debug.service';
import { environment } from 'src/environments/environment';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../utils.service';


@Component({
  selector: 'app-view-component-form',
  templateUrl: 'view-component-form.component.html',
  styleUrls: ['view-component-form.component.scss'],
})
export class ViewComponentFormComponent implements OnInit, AfterViewInit, OnChanges, AfterContentInit {

  @Output() onEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('opColumns') opColumns: OverlayPanel;
  @ViewChild('opField') opField: OverlayPanel;
  public form: UntypedFormGroup;
  @Input("component") public component: any = {};
  @Input("index") public index: number = 0;
  @Input("model") public model: any = {};
  @Input("config") public config: any = {};
  @Input("view") public view: any = {};

  public isLocalModel: boolean = false;
  public localModel: any = {
    metadata: {}
  };
  private cachedModel: any = null;
  @Input("showConfigOptions") public showConfigOptions: boolean = false;

  public isOpenned: boolean = false;
  public temporalModel: any = {};
  public temporalCombos: any = {};
  public entity: any = {};
  public parentFields: any[] = [];
  public modelMonaco: any = {};
  public itemsSplitButton: any = [];
  public resultsAutocomplete: string[];
  public allowedEntities: any[] = [];
  public allowedEntitiesFields: any = {};
  public locked: boolean = false;
  public selectedFieldOperators: any[] = [];
  public editorOptions = { theme: 'vs-dark', language: 'json' };
  tinyMceConfig: any;
  public showTinyMCE: boolean = false;
  public showMapIcon: boolean = false;
  public configurationPermission: boolean = false;
  public zonesPermission: boolean = false;
  public selectedField: any = {};
  public customizableOptions: any = {};
  public columnsFilter: string = "";
  public visibleByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];
  public editableByOptions: any[] = [
    { code: "all", label: this.translateService.instant("view.configure.all") },
    { code: "user", label: this.translateService.instant("view.configure.user_responsible") }
  ];
  public watchModelFields: any[] = [];
  public files: File[];

  constructor(
    public debugService: DebugService,
    private organizationsService: OrganizationsService,
    private entitiesService: EntitiesService,
    private messageService: MessageService,
    private viewsService: ViewsService,
    private dialogService: DialogService,
    private datePipe: DatePipe,
    private loginService: LoginService,
    private monacoLoaderService: MonacoEditorLoaderService,
    private ref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private customizerService: CustomizerService,
    private router: Router,
    public componentService: ComponentService,
    public templateService: TemplatesService,
    public translateService: TranslateService,
    private utilsService: UtilsService
  ) {


    this.form = this.formBuilder.group({});
    this.showMapIcon = loginService.hasPermission("VIEW_MAP_BUTTON");
    this.configurationPermission = loginService.hasPermission("CONFIGURATION");
    this.zonesPermission = loginService.hasPermission("ZONES_READ");

    this.customizerService.moved.subscribe((event) => {
      if (this.component.code == event.customizer.component.code) {
        var field = this.component.fields[event.idx];
        moveItemInArray(this.component.fields, event.from, event.to);
        this.generateFieldsIdx();
      }
    });
    this.customizerService.resized.subscribe((event) => {
      if (this.component.code == event.customizer.component.code) {
        var field = this.component.fields[event.idx];
        field.class = "p-md-" + event.size;
      }
    });
  }

  ngAfterViewInit() {
    this.customizableOptions.component = this.component;
    this.generateFieldsIdx();
  }
  ngAfterContentInit() {
    this.generateFieldsIdx();
  }
  generateFieldsIdx() {

    let idx = 0;
    this.component.fields.forEach(field => {
      field.idx = idx;
      idx++;
    });
  }

  ngOnChanges(changes: any) {

    this.debugService.log(this, changes);

    this.watchModelFields.forEach(field => {
      if (changes.model != null && changes.model.currentValue != null && changes.model.currentValue[field.entityField.model_property] != changes.model.previousValue[field.entityField.model_property]) {
        if (field.entityField?.configuration?.options?.onModelChangeRefresh) {

          //es un combo, recargamos datos
          let value = changes.model.currentValue[field.entityField.model_property];
          this.debugService.log(this, "ngOnChanges for property " + field.entityField.model_property + " to value " + value)
          this.getComboOptions(field.entityField, value);
        }
      }
    });

    if (changes["showConfigOptions"]) {
      if (changes["showConfigOptions"].currentValue) {
        this.customizerService.enable();
      } else {
        this.customizerService.disable();
      }
    }

    if (changes["model"]) {
      //asignamos modelo general solo si no esta personalizado en local
      this.debugService.log(this, "Model change detected");
      if (!this.isLocalModel) {
        let newModel = changes["model"].currentValue;
        if (newModel.metadata == null) newModel.metadata = JSON.parse("{}");
        this.debugService.log(this, newModel);
        //if(JSON.stringify(this.localModel)!=JSON.stringify(newModel)){
        this.localModel = newModel;
        //if(this.cachedModel==null){
        //quitado, se supone que siempre el ultimo valor recibido
        this.cachedModel = JSON.parse(JSON.stringify(this.localModel));
        this.debugService.log(this, "Setting cachedModel");
        this.debugService.log(this, this.cachedModel);
        //}
        /*}else{
          this.debugService.log(this, "No changes in model!");
        }*/
      } else {
        this.debugService.log(this, "localModel are not set, using customized model");
      }
    }
  }

  configureTinyMce(field) {
    const that = this;

    this.tinyMceConfig =
    {
      promotion: false,
      height: 500,
      menubar: true,
      browser_spellcheck: true,
      contextmenu: false,
      toolbar: [
        'undo redo | bold italic underline | fontselect fontsizeselect fontsize fontfamily  formatselect |' +
        'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist |' +
        'forecolor casechange permanentpen formatpainter removeformat | fullscreen | insertfile image media pageembed link | table quickbars | variablesButton  variablesLinesButton | previewButton'
      ],
      plugins: 'quickbars',
      quickbars_insert_toolbar: "quicktable",
      content_css: "../assets/stylesTinymceCustom.css", //"https://assets.codepen.io/413052/templates-noneditable-styling-content.css",
      noneditable_noneditable_class: "mceNonEditable",
      toolbar_mode: 'wrap',
      language: 'es',
      language_url: '/assets/tinymce/langs/es.js',
      relative_urls: false,
      remove_script_host: false,
      document_base_url: 'https://app.axialcrm.com/',
      file_picker_types: 'image',
      paste_data_images: false,
      /* and here's our custom image picker*/
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function (e) {
          var file = e.target["files"][0];
          var reader = new FileReader();
          reader.onload = (event: any) => {
            var datafile = {
              FileName: file.name,
              ContentType: file.type,
              Length: file.size,
              b64: event.target.result
            };
            that.templateService.UploadImage(datafile).subscribe(
              (data: any) => {
                cb(data.location);
                //FALTA Pintar la imagen con la url en el visor.... work in progress
              },
              error => {
                that.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            );
          };
          reader.readAsDataURL(file);
        };

        input.click();
      }
    };

    //const that = this;
    if (field.configuration != null && field.configuration.allowMoreInfo) {
      let params = "";
      //añadimos otros posibles parametros configurados
      if (field.configuration.options.params != null && Array.isArray(field.configuration.options.params)) {
        field.configuration.options.params.forEach(param => {
          let value = eval(param.value);
          if (typeof (value) != "undefined" && value != null) params += (params != "" ? "&" : "") + param.name + "=" + value;
        });

        if (params != "") params = "?" + params;

        this.viewsService.getComboOptions(field.configuration.options.url + params).subscribe(
          data => {
            let result = data;
            let showLinesTransaction = false;
            result.forEach(element => {
              if (element.entity_navigation == "transactionsLines") showLinesTransaction = true;
            });
            this.tinyMceConfig["setup"] = function (editor) {
              let toggleState = false;
              /* example, adding a toolbar menu button */
              editor.ui.registry.addMenuButton('variablesButton', {
                text: 'Variables',
                fetch: function (callback) {
                  //TODO: SELECCIONAR EL QUE CORRESPONDA DE templates_types_id no entiendo como saber cual es el disponible.
                  let items = [];
                  result.forEach(element => {
                    items.push({
                      type: 'nestedmenuitem',
                      text: element.entity_name,
                      getSubmenuItems: function () {
                        let aux = [];
                        element.options.forEach(option => {
                          aux.push({
                            type: 'menuitem',
                            text: option.text,
                            //icon: 'unlock',
                            onAction: function () {
                              editor.insertContent(option.action);
                            }
                          })
                        });
                        return aux;
                      }
                    });
                  });
                  callback(items);
                }
              });
              if (showLinesTransaction) {
                editor.ui.registry.addButton('variablesLinesButton', {
                  text: 'Añadir bloque de lineas',
                  onAction: function () {
                    //that.addForeach()
                    editor.insertContent('<p class="bucle mceNonEditable">@foreach(var item in @Model.transactionsLines){</p><table style=\"border-collapse: collapse; width: 100%;\" border=\"1\">\n<tbody>\n<tr>\n<td></td></tr>\n</tbody>\n</table><p class="bucle mceNonEditable">}</p>', { format: 'html' });

                  }
                })
              }
            };
            this.showTinyMCE = true;
          }
        );
      }
    } else {
      this.showTinyMCE = true;
    }

  }

  onBasicUpload(file, url) {
    var datafile = {
      FileName: file.name,
      ContentType: file.type,
      Length: file.size,
      b64: url
    };
    this.templateService.UploadImage(datafile).subscribe(
      (data: any) => {
        //FALTA Pintar la imagen con la url en el visor.... work in progress
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    );
  }

  configField($event, field: any) {
    this.selectedField = field;
    this.selectedFieldOperators = this.getFilterOperators(this.selectedField);
    this.opField.toggle($event);
  }

  myFun() {
    //ya no lo hacemos proque se quedaba pillado hasta que se movía el ratón
    this.onEvent.emit({ event: "action", valid: true, component: this.component.code, action: { name: "preview" }, data: this.model });
  }

  ngOnInit(): void {
    this.component.fields.forEach(field => {
      let property = field.model_property;
      if (field.model_property_label != null && field.model_property_label != "") {
        property = field.model_property_label;
      }
      try {
        this.temporalModel[property] = eval("this.model." + property);
      } catch (e) {
        console.warn(`Error evaluando this.model.${property}`);
      }
    });

    this.entitiesService.getByCode(this.component.entity).subscribe(
      data => {
        this.entity = data;
        //onsole.log(this.entity);
        if (this.entity.metadata?.tracking?.enabled) {
          this.configurationPermission = this.loginService.hasPermission("CONFIGURATION");
        } else {
          this.configurationPermission = false;
        }
        this.entity.fields.forEach(entityField => {
          //si no es de la entidad actual no permitimos modificarlo
          if (entityField.entity_id != this.entity.id) entityField.unmodifiable = true;
        });

        let newFields: any = [];
        let user_role_id = localStorage.getItem("roleId") + "";
        this.component.fields.forEach(field => {
          let hasRole = true;
          let entityField = this.entity.fields.find(m => m.id == field.entity_field_id);
          if (!this.model.id && entityField?.default_value) {
            if (entityField.data_type == "number") {
              this.model[entityField.model_property] = parseInt(entityField.default_value);
            } else if (entityField.data_type == "boolean") {
              this.model[entityField.model_property] = JSON.parse(entityField.default_value);
            } else {
              this.model[entityField.model_property] = entityField.default_value;
            }
          }
          let viewRoles;
          if (entityField?.configuration != null) viewRoles = entityField.configuration['viewRoles'];
          if (viewRoles != null && viewRoles.length > 0) {
            if (!viewRoles.find(role => role == user_role_id)) hasRole = false;
          }


          if (entityField != null && typeof (entityField) !== "undefined" && !entityField.is_disabled && hasRole) {

            newFields.push(field);
            field.entityField = entityField;

            if (field.entityField.control_type == "dropdown" || field.entityField.control_type == "dropdown-multiple") {
              if (field.entityField.configuration.parent_field_id != null) {

                this.addToParentField(field.entityField.configuration.parent_field_id, field);
              }
              //si se debe refrescar onModelChangeRefresh, lo añadimos a la lista de campo que escuchamos cambios
              if (field.entityField?.configuration?.options?.onModelChangeRefresh != null) {
                this.watchModelFields.push(field);
              }

              this.debugService.log(this, "ngOnInit");
              this.getComboOptions(field.entityField);

            } else if (field.entityField.control_type == "monaco") {
              this.modelMonaco[field.entityField?.model_property] = JSON.stringify(this.getModelValue(this.model, field), null, "\t");
            } else if (field.entityField.control_type == "input-editor") {
              this.configureTinyMce(field.entityField);
            }
            if (!field.entityField?.is_base_field) {
              if (this.model.metadata == null) this.model.metadata = JSON.parse("{}");
            }

            //añadimos campos y validaciones
            if (field.required && this.componentService.isVisibleField(field, this.model)) {
              this.form.addControl(field.entityField.model_property, new UntypedFormControl('', Validators.required));
            } else {
              this.form.addControl(field.entityField.model_property, new UntypedFormControl());
            }

          }

          //fin de foreach.
        });
        this.component.fields = newFields;
      },
      error => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    );
  }

  isOpen() {
    return this.isOpenned;
  }

  setModel(model: any) {
    this.localModel = model;
    if (this.localModel.metadata == null) this.localModel.metadata = JSON.parse("{}");

    this.isLocalModel = true;
  }

  getModel(): any {
    return this.model;
  }

  editorInit(editor: MonacoStandaloneCodeEditor) {
  }

  onMonacoChange(event, field) {
    this.model[field.entityField?.model_property] = JSON.parse(this.modelMonaco[field.entityField?.model_property]);
  }

  onAutocompleteSearch(event, field) {
    let filtered: any[] = [];
    let params = "?" + field.configuration.field_text + "=" + event.query;
    this.viewsService.getComboOptions(field.configuration.options.url + params).subscribe(
      data => {
        this.resultsAutocomplete = data.rows;
      }
    );
  }
  onSelectAutocomplete(model, field) {
    this.onEvent.emit({ event: "text-autocomplete", valid: true, component: this.component.code, action: { name: "select" }, data: this.model, entityField: field.entityField, });
  }
  onKeyUpAutocomplete($event, field, currentValue) {
    this.form.get(field.entityField.model_property).setValue(currentValue);
  }

  addToParentField(parent_field_id: number, field: any) {
    let exists = this.parentFields.filter(m => m.id == parent_field_id);
    let parentField = {
      id: parent_field_id,
      childs: []
    };
    if (exists.length > 0) {
      parentField = exists[0];
    } else {
      this.parentFields.push(parentField);
    }
    parentField.childs.push(field);
  }

  getTooltipAction(action: any) {
    let tooltip = "";
    if (action.tooltip != null) {
      tooltip = action.tooltip;
    } else if (action.tooltipExpression != null) {
      tooltip = eval(action.tooltipExpression);
    }
    return tooltip;
  }

  getFieldPreActions(field: any) {
    let actions: any[] = [];
    if (field.entityField?.configuration?.preActions) {
      field.entityField?.configuration?.preActions.forEach(action => {
        let visible = this.isActionVisible(action) && !this.isSplitButton(action) && this.isFieldActionsVisible(field);
        if (visible) actions.push(action);
      });
    }
    return actions;
  }

  getFieldActions(field: any) {
    let actions: any[] = [];
    if (field.entityField?.configuration?.actions) {
      field.entityField?.configuration?.actions.forEach(action => {
        let visible = this.isActionVisible(action) && !this.isSplitButton(action) && this.isFieldActionsVisible(field);
        if (visible) actions.push(action);
      });
    }
    return actions;
  }

  getFieldActionsSplit(field: any) {
    let actions: any[] = [];
    if (field.entityField?.configuration?.actions) {
      field.entityField?.configuration?.actions.forEach(action => {
        let visible = this.isActionVisible(action) && this.isSplitButton(action) && this.isFieldActionsVisible(field);
        if (visible) {
          action.options.forEach(option => {
            option.command = () => {
              this.onEvent.emit({ event: "action", valid: true, component: this.component.code, action: option, data: this.model });
            }
          });
          actions.push(action);
        }
      });
    }
    return actions;
  }

  getStyledClass(field: any) {
    let classNames = "";
    if (field.entityField.configuration?.styledClass != null) {
      classNames = field.entityField.configuration?.styledClass;
    } else if (field.entityField.configuration?.styledClassExpression != null) {
      classNames = eval(field.entityField.configuration?.styledClassExpression);
    }
    return classNames;
  }

  getFieldFormat(field: any) {
    let formatFn = field.formatFn;
    if (formatFn == null && field.entityField?.configuration != null && field.entityField?.configuration?.formatFn != null) {
      formatFn = field.entityField.configuration.formatFn;
    }
    return formatFn;
  }

  getModelValue(model, field) {
    let value: any = null;

    let property = field.entityField?.model_property;
    if (field.entityField?.model_property_label != null && field.entityField?.model_property_label != "") {
      property = field.entityField?.model_property_label;
    }
    if (field?.entityField?.is_base_field == false) {
      //Si el campo no es base, entonces es un custom. Lo añadimos al value:
      try {

        if (this.component.entity == field.entityField?.entity_code) {
          //Si es la misma entidad la buscamos en el metadata
          value = eval("model.metadata." + property);
        } else {
          //Si es distinta entidad, la propiedad model_property tiene la ruta entera (Ejem: organization.metadata.custom_232)

          const modelCustom = property.replaceAll(".", '?.');
          value = eval("model." + modelCustom);
        }

      } catch (e: any) {
        console.warn("error evaluando:" + model.metadata + " " + e.message);
      }

      if (field.entityField?.control_type == "checkbox") {
        if (value) {
          value = this.translateService.instant("general.yes");
        } else {
          value = this.translateService.instant("general.no");
        }
      }

      if (field.entityField?.control_type == "dropdown") {
        //sacamos valor del combo
        //let selectedValue = model.metadata[field.entityField?.model_property]; // eval("model." + field.entityField?.model_property);
        let selectedValue = value;
        if (this.temporalCombos[property]) {
          this.temporalCombos[property].forEach((item) => {
            if (eval("item." + field.entityField?.configuration?.field_value) == selectedValue) {
              this.temporalModel[property] = selectedValue;
              value = eval("item." + field.entityField?.configuration?.field_text);
            }
          });
        }
      } else if (field.entityField?.control_type == "dropdown-multiple") {
        //sacamos valor del multiselect
        var selectedValues = value;
        /*if (this.component.entity != field.entityField?.entity_code) {
           //si es distinta entidad el model_property tiene la ruta correcta
           //console.log(this.model, field)
           const modelCustom = field.entityField?.model_property.replaceAll(".", '?.');
           console.log(modelCustom)
 
           selectedValues = eval("model." + modelCustom);
         } else {
           //Si es la misma entidad, la buscamos en el metadata.
           selectedValues = model.metadata[field.entityField?.model_property];
         }*/

        //let selectedValues = model.metadata[field.model_property]; // eval("model." + field.entityField?.model_property);
        if (!(selectedValues instanceof Array)) selectedValues = [selectedValues];
        if (this.temporalCombos[property]) {
          value = "";
          selectedValues.forEach(selectedValue => {
            this.temporalCombos[property].forEach((item) => {
              if (eval("item." + field.entityField?.configuration?.field_value) == selectedValue) {
                this.temporalModel[property] = selectedValue;
                if (value != "") value += ", ";
                value += eval("item." + field.entityField?.configuration?.field_text);
              }
            });
          });
        }
      }

    } else {
      if (field.entityField?.control_type == "dropdown" || field.entityField?.control_type == "dropdown-multiple") {
        //sacamos valor del combo
        //Lo ponemos así por tema de tiempos, para que no falle ya que pone model.organization.zone_id y fallaba hasta que estuviese cargado así que le pongo model.organization?.zone_id y se arregla.
        const modelCustom = property.replaceAll(".", '?.');
        let selectedValue = eval("model." + modelCustom);
        if (this.temporalCombos[property]) {
          this.temporalCombos[property].forEach((item) => {
            if (eval("item." + field.entityField?.configuration?.field_value) == selectedValue) {
              this.temporalModel[property] = selectedValue;
              value = eval("item." + field.entityField?.configuration?.field_text);
            }
          });
        }
      } else {
        try {
          this.temporalModel[property] = eval("model." + property);

        } catch (e) {
          //nothing
        }
        value = this.temporalModel[property];
      }
    }

    if (field.entityField?.control_type == "input-switch") {
      if (value) {
        value = this.translateService.instant("general.yes");
      } else {
        value = this.translateService.instant("general.no");
      }
    }

    let formatFn = this.getFieldFormat(field);
    if (formatFn != null) {
      let format = formatFn.split("|");

      if (format[0] == "date") value = this.utilsService.formatDate(value, format);
      if (format[0] == "suffix") value = this.utilsService.formatSuffix(value, format);
      if (format[0] == "phone") value = this.formatPhone(value, format); //No se llama al ustils porque hay cosas distintas...
      if (format[0] == "mail") value = this.formatMail(value, format); //No se llama al ustils porque hay cosas distintas...
      if (format[0] == "www") value = this.utilsService.formatWWW(value, format);
      if (format[0] == "badge") value = this.formatBadge(field, value, format);  //No se llama al ustils porque hay cosas distintas...
      if (format[0] == "badgeColor") value = this.formatBadgeColor(field, value, format); //No se llama al ustils porque hay cosas distintas...
      if (format[0] == "image") value = this.utilsService.formatImage(field, format);
      if (format[0] == "currency") value = this.utilsService.formatCurrency(value, format);
      if (format[0] == "number") value = this.utilsService.formatNumber(value, format);
    }
    return value;

  }

  formatPhone(value: any, args: any[]) {
    if (value == null) return "";
    let formattedValue = "<i class='fas fa-phone-square'></i> <a href='tel:" + value + "'>" + value + "</a>";
    return formattedValue;
  }
  formatMail(value: any, args: any[]) {
    if (value == null) return "";
    let formattedValue = "<i class='fas fa-envelope'></i> <a href='mailto:" + value + "'>" + value + "</a>";
    return formattedValue;
  }

  formatBadge(field: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    let formattedValue = "";
    if (field) {
      if (args[1] != "") {
        try {
          let valorID = eval("this.model." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          console.warn(`Error evaluando this.model.${field.entityField.model_property}`);
        }
      }
      className = "badge badge-block " + subClassName;
      formattedValue = "<div class='" + className + "'>" + value + "</div>";
    }

    return formattedValue;
  }

  formatBadgeColor(field: any, value: any, args: any[]) {
    if (value == null) return "";
    let className = "";
    let subClassName = "";
    if (field) {
      let refValue = "";
      if (args[1] != "") {
        try {
          let valorID = eval("this.model." + field.model_property);
          subClassName = args[1] + valorID;
        } catch (e) {
          this.debugService.log(this, `Error evaluando this.model.${field.entityField.model_property}`);
        }
      }

      if (typeof (value) !== "undefined" && value != "" && value != null) {
        className = "p-badge p-component p-badge-no-gutter p-badge-xs  p-mr-2 " + subClassName;
        var formattedValue = "<div class='" + className + "' title='" + value + "'></div>" + value;
      } else {
        var formattedValue = "<div></div>";
      }

    }

    return formattedValue;
  }

  getLabel(field: any) {
    let label = field.label;
    if (label == "" || label == null) label = field.entityField?.description;
    return label;
  }

  getComboOptions(field, selectValue = null, filterValue = null) {

    if (field.configuration == null) return;
    if (field.configuration.options?.type == "api") {
      //añadimos campos enlazados
      let params = "";
      if (filterValue != "" && filterValue != null) params = "&text=" + filterValue;
      if (field.configuration.parent_field_id != null) {
        let parentFields = this.component.fields.filter(m => m.entityField?.id == field.configuration.parent_field_id);
        if (parentFields.length > 0) {
          try {
            let parentValue = eval("this.model." + parentFields[0].entityField?.model_property);
            if (parentValue == null && field.configuration.parent_field_required) parentValue = "0";
            if (parentValue != null) params = parentFields[0].entityField.model_property + "=" + parentValue;
          } catch (e: any) {
            console.log("error badge: " + e.message);
          }
        }
      }
      if (this.component.mode == "filter") {
        params += (params != "" ? "&" : "") + "isOnFilter=true";
      }
      //añadimos otros posibles parametros configurados
      if (field.configuration.options.params != null && Array.isArray(field.configuration.options.params)) {
        field.configuration.options.params.forEach(param => {
          let value = eval(param.value);

          if (typeof (value) != "undefined" && value != null && !isNaN(value)) params += (params != "" ? "&" : "") + param.name + "=" + value;
        });
      }
      if (params != "") params = "?" + params;

      this.debugService.log(this, "getComboOptions " + field.configuration.options.url + params);

      this.viewsService.getComboOptions(field.configuration.options.url + params).subscribe(
        data => {
          this.temporalCombos[field.model_property] = data.rows;
          //si el seleccionado no existe, lo vaciamos
          let selectedItem = null;
          let selectedValue = null;
          try {
            selectedValue = eval("this.model." + field.model_property);
            if (selectedValue == null || typeof (selectedValue) == "undefined") selectedValue = eval("this.cachedModel." + field.model_property);
            //Si solo hay una opción 
            /*if (selectedValue == null || typeof (selectedValue) == "undefined") {
              if (data.rows.length == 1) {
                selectedValue = eval("data.rows[0]." + field.configuration?.field_value);
              }
            }*/
          } catch (e) {
            console.warn(`Error evaluando this.model.${field.model_property}`);
          }
          this.debugService.log(this, "Evaluated value for " + field.model_property + " is " + selectedValue);
          if (selectedValue != null && typeof (selectedValue) != "undefined") {
            this.temporalCombos[field.model_property].forEach((item) => {
              if (eval("item." + field.configuration?.field_value) == selectedValue) {
                selectedItem = item;
                this.onChange(selectedItem.id, { entityField: field }, selectedValue); //Si está preseleccionado, se llama al onchange para recargar
              }
            });
          }
          //este codigo sigueinte parece quitar el valor en caso de no estar ya en la lista
          //pero esto trae problemas si por ejemplo no tiene acceso a la organización en cuestión aunque exista, etc.
          //no tengo claro cual es la solución, pero quitar el valor sin que el usaurio sea consciente no, porque otro usaurio que si podía estableció el valor correctamente
          /*if (selectedItem == null) {
            //no tengo claro que resuelve esto
            //no deberia quitar un valor sin ser consciente el usuario
            if (this.temporalCombos[field.model_property] != "undefined") {
              try {
                eval("this.localModel." + field.model_property + "=null");
              } catch (e) {
                console.warn(`Error evaluando this.localModel.${field.model_property}=null`);
              }
            }
            this.model[field.navigation_property] = null;
          }*/
          //veo que reestablece la propiedad pero no entiendo por que hace esto
          if (selectedValue != null && typeof (selectedValue) != "undefined") {
            this.debugService.log(this, "Setting property " + field.model_property + " = " + selectedValue);
            this.model[field.model_property] = selectedValue;
          }
        },
        error => {
          this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
        }
      );
    } else {
      this.temporalCombos[field.model_property] = field.configuration.options.items;
    }
  }

  onModelChange(value, field) {
    if (value != null && field.entityField?.configuration?.options?.onModelChangeRefresh) {
      this.getComboOptions(field.entityField);
    }
  }

  getSplitOptions(field) {
    this.itemsSplitButton = [];
    if (field.options == null) return;
    //añadimos otros posibles parametros configurados
    if (field.options != null && Array.isArray(field.options)) {
      field.options.forEach(param => {
        let aux: any = {};
        aux.label = param.label;
        if (param.icon != null && Array.isArray(param.icon)) aux.icon = param.icon.join(' ');
        aux.command = () => {
          this.onEvent.emit({ event: "action", valid: true, component: this.component.code, action: param, data: this.model });
        }
        this.itemsSplitButton.push(aux);

      });
    }
  }

  fireEvent(component: string, event: string, args: any) {
    if (event == "reload") {
      //nada
    }
  }

  getActions(actions: any[], position: string) {
    if (actions == null) return [];
    return actions.filter(m => m.position == position);
  }

  isFieldActionDisabled(field: any, action: any) {

    let ret = false;
    if (action.disabled != null) {
      try {
        //Errores si no existe la entidad.
        let propertyNullCheck = field.entityField?.model_property.replace(".", "?.");
        let value = eval("this.model." + propertyNullCheck);
        if (typeof value == undefined) value = null;
        ret = eval(action.disabled);
      } catch (e) {
      }
    }
    return ret;
  }

  isFieldActionsVisible(field: any) {
    let ret = false;
    if (field.showActions == true) ret = true;
    return ret;
  }

  isFieldVisible(field: any) {
    let visible: boolean = true;
    if (field.ngIf != null) {
      visible = eval(field.ngIf);
      this.debugService.log(this, "isFieldVisible for " + field.entityField?.model_property + ": " + field.ngIf + ". Visible is " + visible);
    }
    return visible;
  }

  getComponentFields() {
    let fields = this.component.fields.filter(m => {
      return this.isFieldVisible(m);
    });
    return fields;
  }

  onChange(newValue: any, field: any, oldvalue: any) {

    if (this.model.metadata == null) this.model.metadata = JSON.parse("{}");

    //si es el parent_field_id de algun combo, recargamos valores
    if (field.entityField?.control_type == "dropdown" || field.entityField?.control_type == "dropdown-multiple") {
      let parentFields = this.parentFields.filter(m => m.id == field.entity_field_id);
      parentFields.forEach(parentField => {
        parentField.childs.forEach(child => {
          this.debugService.log(this, "onChange")
          this.getComboOptions(child.entityField);
        });
      });
    }

    //Si el campo es un combo, rellenamos la propiedad de navegación relacionada si existe:
    let selectedItem = null;
    if (field.entityField?.control_type == "dropdown") {
      //obtener valor para el seleccionado
      if (this.temporalCombos[field.entityField?.model_property] != undefined) {
        this.temporalCombos[field.entityField?.model_property].forEach((item) => {
          if (eval("item." + field.entityField?.configuration?.field_value) == newValue?.value) {
            selectedItem = item;
          }
          if (eval("item." + field.entityField?.configuration?.field_value) == newValue) {
            selectedItem = item;
          }
        });
      }
      //si tiene propiedad de navegacion enlazada lo cargamos
      if (field.entityField?.navigation_property != null) {
        this.debugService.log(this, "Setting property " + field.entityField?.navigation_property);
        eval("this.model." + field.entityField?.navigation_property + " = selectedItem");
      }
    }
    this.onEvent.emit({ event: "onChange", component: this.component.code, entityField: field.entityField, value: newValue, data: this.model, selectedItem: selectedItem });
  }

  onFilter(event, entityField) {
    if (entityField.entityField.model_property == "organization_id" || entityField.entityField.model_property == "parent_organization_id" || entityField.entityField.model_property == "product_id") {

      if (event.filter?.length > 1 || event.filter == null) {
        this.debugService.log(this, "onFilter");
        this.getComboOptions(entityField.entityField, null, event.filter);
      }
    }
  }

  onActionClick(action, item) {
    if (!this.form.valid) console.log(this.form);
    let eventPayload: any = { event: "action", valid: this.form.valid, component: this.component.code, action: action, data: this.model };
    this.debugService.log(this, "onActionClick");
    this.debugService.log(this, eventPayload);
    this.onEvent.emit(eventPayload);
  }

  onFieldActionClick(field, action, value) {
    if (!this.form.valid) console.log(this.form);
    if (action.name == "openURL") {
      if (value.startsWith('http')) window.open(value, "_blank");
      else window.open("http://" + value, "_blank");
    }

    if (action.name == "open") {
      this.openNewPage(value, field.entityField.model_property);
    }

    if (action.name == "edit") {
      if (("." + field.entityField.model_property).endsWith(".contact_id")) { this.editContact(value); };
      if (("." + field.entityField.model_property).endsWith(".organization_id")) { this.editOrganization(value); };
    }


    //if (event.event == "fieldAction" && event.action.name == "open" && event.entityField.model_property == "organization_id") this.openNewPage(event.value);
    /*  openNewPage(id) {
      const url = this.router.serializeUrl(this.router.createUrlTree(['/organizations/', 0, id], { queryParams: {} }));
      window.open(url, '_blank');
    }*/

    this.onEvent.emit({ event: "fieldAction", valid: this.form.valid, component: this.component.code, entityField: field.entityField, action: action, value: value, data: this.model });
  }

  openNewPage(id, model_property) {
    var url;
    if ("." + model_property.endsWith(".organization_id")) url = this.router.serializeUrl(this.router.createUrlTree(['/organizations/', 0, id], { queryParams: {} }));
    window.open(url, '_blank');
  }

  isActionVisible(action: any) {
    let visible: boolean = true;
    if (action.ngIf != null) {
      visible = eval(action.ngIf);
    }
    return visible;
  }

  isSplitButton(action: any) {

    let visible: boolean = false;
    if (action.options != null) {
      visible = true;
      this.getSplitOptions(action);
    }
    return visible;
  }

  showTracking() {
    if (!this.configurationPermission) return; //Si no tiene el permiso, no hace nada.

    if (this.component.fields == null || this.component.fields.length == 0) return;
    let entity_id = this.component.fields[0].entityField.entity_id;
    const ref = this.dialogService.open(TrackingComponent, {
      data: {
        entity_id: entity_id,
        entity_pk_id: this.model.id
      },
      header: this.translateService.instant("general.tracking_title"),// "Información de cambios",
      width: '500px'
    });
    ref.onClose.subscribe((data: any) => {
      //nothing
    })
  }

  showColumnsSelector($event) {
    this.allowedEntities = [];
    this.allowedEntitiesFields = {};
    this.entity.fields.forEach(field => {
      if (this.allowedEntities.find(m => m.code == field.entity_code) == null) {
        this.allowedEntities.push({ code: field.entity_code, name: field.entity_name });
        this.prepareAllowedEntityFields(field.entity_code);
      }
    });
    this.opColumns.toggle($event);
  }

  prepareAllowedEntityFields(entity_code: string) {
    let fields = this.entity.fields.filter(m => m.entity_code == entity_code);
    fields.forEach((field) => {
      var selected = this.component.fields.find(m => m.entity_field_id == field.id);
      field.selected = selected != null;
    });
    this.allowedEntitiesFields[entity_code] = fields;
  }

  removeField(entity_field_id) {
    this.component.fields = this.component.fields.filter(m => m.entity_field_id != entity_field_id);
    this.opField.hide();
  }

  onFieldChange($event, field) {
    if ($event.checked) {
      //añadimos al final
      let viewField = {
        entity_field_id: field.id,
        entityField: field,
        class: "p-md-4",
        model_property: field.model_property,
        readonly: false,
        idx: this.component.fields.length
      };
      this.component.fields.push(viewField);
      setTimeout((m) => {
        this.customizerService.disable();
        this.customizerService.enable();
      });
    } else {
      this.removeField(field.id);
    }
  }

  getFilterOperators(selectedField) {
    //dependiendo del selectedField.entityField.data_type podria salir unos tipos u otros, ahora mismo para number o date son los mismo

    let options = [
      { code: ">=", label: this.translateService.instant("general.filter.greater_equal") },// "Mayor o igual" },
      { code: ">", label: this.translateService.instant("general.filter.greater") },//"Mayor" },
      { code: "==", label: this.translateService.instant("general.filter.equal") },//"Igual" },
      { code: "<", label: this.translateService.instant("general.filter.less") },//"Menor" },
      { code: "<=", label: this.translateService.instant("general.filter.less_equal") },//"Menor o igual" }
    ];
    return options;
  }

  //Edit entities
  editContact(contact_id) {
    this.router.navigate(["/contacts/" + contact_id]);
    this.onEvent.emit({ event: "closeDialog", component: this.component.code, action: { name: "closeDialog" }, data: {} });
  }

  editOrganization(organization_id) {
    const ref = this.dialogService.open(OrganizationsDetailGeneralComponent, {
      data: {
        id: organization_id,
      },
      //header: "Detalles de la organización",
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      this.component.fields.forEach(field => {
        if (("." + field.entityField.model_property).endsWith(".organization_id")) {
          this.getComboOptions(field.entityField);
        }
      });
      this.onEvent.emit({ event: "refreshAll", component: this.component.code, action: { name: "refreshAll" }, data: data });

    });
  }

  dateToIso($event: Date, metadata: boolean, property: string) {
    if (!metadata) {
      this.localModel[property] = $event.toISOString();
    } else {
      this.localModel['metadata'][property] = $event.toISOString();
    }
  }

  getActionLabel(action: any) {
    //return this.componentService.getActionLabel(action, this.view.code + "-" + this.component.code);
    return this.componentService.getActionLabel(action, this.component.code);
  }

}
